<template>
  <div class="home">
    <HelloWorld msg="Private System"/>
  </div>
</template>


<style>
.home {
  padding-top: 50px;
}
</style>
<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  }
}
</script>
